import { useDispatch } from "react-redux";
import { actionTypes } from 'redux-resource';
import { Action } from 'utils/action';
import { profileSelfId } from 'profile/selectors';
import { queryClient } from '../queries/client';

export const profileCommonParams = {
  resourceType: 'profile',
  url: '/api/v1/users/self',
  id: profileSelfId
};

export const getProfileAction = new Action({
  ...profileCommonParams,
  requestKey: 'getProfile',
  effect: 'read',
  method: 'GET'
});

export const useUpdateUserPreferences = () => {
  const dispatch = useDispatch();
  invalidateCurrentUser();
  return (newUserPreferences, userResourceId) => {
    dispatch({
      type: actionTypes.UPDATE_RESOURCES,
      resources: {
        profile: {
          [userResourceId]: { user_preferences: { ...newUserPreferences } }
        }
      }
    });
  };
};

// Invalidate currentUser in the query cache. This function is only necessary until
// we've completed the migration to React Query. Once that's complete, invalidation
// should happen onSuccess in any mutation that updates the user's profile.
export const invalidateCurrentUser = (
  _dispatch = undefined, _action = undefined, _res = undefined
) => {
  queryClient.invalidateQueries('currentUser');
};

// If this gets called on a page that displays public users, it may cause issues
export const invalidatePublicUsers = (dispatch, _action, res) => {
  const vanitySlug = res.resources[0].vanity_slug;
  dispatch({
    type: actionTypes.DELETE_RESOURCES,
    resources: {
      publicUsers: [vanitySlug]
    }
  });
};

export const invalidatePublicUsersAndCurrentUser = (dispatch, _action, res) => {
  invalidatePublicUsers(dispatch, _action, res);
  invalidateCurrentUser();
};

export const updateUserTosAction = new Action({
  resourceType: 'profile',
  url: '/api/v1/users/self/accept_terms',
  id: 'acceptTerms',
  requestKey: 'acceptTerms',
  effect: 'read',
  method: 'POST',
  onSuccess: invalidateCurrentUser
});

// Useful when two elements on the same page update the profile.
export const makeUpdateProfileAction = key => new Action({
  ...profileCommonParams,
  requestKey: 'updateProfile_' + key,
  effect: 'update',
  method: 'PUT',
  onSuccess: invalidatePublicUsersAndCurrentUser
});

export const updateProfileAction = makeUpdateProfileAction('');
